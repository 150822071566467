import React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../templates/layout';

const Privacy = () => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <h2>Google Analyticsについて</h2>
    <p>
      本サイトのいくつかのページでは，Google社のサービスであるGoogle
      Analyticsを利用しています． Google
      Analyticsは，本サイトが発行するクッキーを利用して，ユーザの訪問履歴を収集します．
    </p>
    <p>
      ユーザは，本サイトを利用することでcookieの使用に許可を与えたものとみなします．
    </p>
    <p>
      Google Analyticsオプトアウトアドオンを利用することで， Google
      Analyticsによる情報の収集を停止することができます．
    </p>

    <ul>
      <li>
        Google Analyticsの利用規約
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.google.com/analytics/terms/jp.html"
        >
          http://www.google.com/analytics/terms/jp.html
        </a>
      </li>
      <li>
        Googleのプライバシーポリシー
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.google.com/intl/ja/policies/privacy/"
        >
          http://www.google.com/intl/ja/policies/privacy/
        </a>
      </li>
      <li>
        Google Analyticsオプトアウトアドオン
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href="https://tools.google.com/dlpage/gaoptout?hl=ja"
        >
          https://tools.google.com/dlpage/gaoptout?hl=ja
        </a>
      </li>
    </ul>
  </Layout>
);

export default Privacy;
